// C:\Users\ignac\OneDrive\Escritorio\imagenes_productos\callcenter3\src\components\Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';
import DarkModeSwitch from './DarkModeSwitch'; // Ajusta la ruta si es necesario

function Sidebar() {
  const navigate = useNavigate();
  const role = localStorage.getItem('role') || 'Supervisor';

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setIsOpen(false);
      } else {
        setIsMobile(false);
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    alert('Sesión cerrada correctamente.');
    navigate('/');
  };

  const opcionesComunes = [
    { nombre: 'Pedidos', ruta: '/welcome/pedidos' },
    { nombre: 'Pedidos Históricos', ruta: '/welcome/pedidos-historicos' },
    { nombre: 'Móviles', ruta: '/welcome/moviles' },
  ];
  // Se actualiza la opción "Mapa" para usar GoogleCallCenterMap
  const opcionesAdminSupervisor = [
    
    { nombre: 'GPS', ruta: '/welcome/gps' },
  ];
  const opcionesAdmin = [
    { nombre: 'Ruta Moviles', ruta: '/welcome/ruta-moviles' },
    { nombre: 'Enviar Mensajes', ruta: '/welcome/enviar-mensajes' },
    { nombre: 'Gestión de Usuarios', ruta: '/welcome/gestion-usuarios' },
  ];

  let opciones = opcionesComunes;
  if (role === 'Administrador') {
    opciones = [...opcionesComunes, ...opcionesAdminSupervisor, ...opcionesAdmin];
  } else if (role === 'Supervisor') {
    opciones = [...opcionesComunes, ...opcionesAdminSupervisor];
  }

  return (
    <div className="sidebar-container">
      <div className="sidebar-header">
        <h2>Menú</h2>
        {isMobile && (
          <div className="hamburger" onClick={toggleSidebar}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        )}
      </div>
      <div className={`sidebar-content ${isOpen ? 'open' : ''}`}>
        <ul>
          {opciones.map((op) => (
            <li key={op.nombre}>
              <NavLink to={op.ruta} className={({ isActive }) => (isActive ? 'active' : '')}>
                {op.nombre}
              </NavLink>
            </li>
          ))}
        </ul>
        <button className="logout-btn" onClick={handleLogout}>
          Cerrar Sesión
        </button>
        <div className="dark-mode-toggle">
          <DarkModeSwitch />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
