import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import LoginForm from './components/LoginForm';
import Dashboard from './pages/Dashboard';
import Pedidos from './pages/Pedidos';
import PedidosHistoricos from './pages/PedidosHistoricos';
import Moviles from './pages/Moviles';
import EnviarMensajesTelegram from './pages/EnviarMensajesTelegram';
import GoogleCallCenterMap from './pages/GoogleCallCenterMap'; // Mantenlo igual
import UsuariosRegistrados from './telegram/UsuariosRegistrados';
import PedidosRealizados from './telegram/PedidosRealizados';
import GestionUsuarios from './pages/GestionUsuarios';
import RutaMoviles from './pages/RutaMoviles';
import ProtectedRoute from './ProtectedRoute';
import GPS from './pages/GPS';
import { DarkModeProvider } from './components/DarkModeContext';
import { LoadScript } from '@react-google-maps/api';
import './styles/googleMapsOverrides.css';


const googleMapsApiKey = 'AIzaSyBcUE3zRi5BtrcPo3ilC3fj1lFRMCXNYyg';

// Wrapper para forzar la reinstanciación del mapa cuando la ruta cambie
function MapWrapper() {
  const location = useLocation();
  return <GoogleCallCenterMap key={location.key} />;
}

function App() {
  return (
    <DarkModeProvider>
      {/* Cargamos el script una sola vez para toda la aplicación */}
      <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={[]}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginForm />} />
            <Route
              path="/welcome/*"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="pedidos" element={<Pedidos />} />
              <Route path="pedidos-historicos" element={<PedidosHistoricos />} />
              <Route path="moviles" element={<Moviles />} />
              
              {/*
                En lugar de usar directamente <GoogleCallCenterMap />,
                usamos <MapWrapper /> para forzar la reinstanciación al cambiar de ruta.
              */}
              <Route path="google-callcenter-map" element={<MapWrapper />} />

              <Route path="usuarios-registrados" element={<UsuariosRegistrados />} />
              <Route path="pedidos-realizados" element={<PedidosRealizados />} />

              {/* Rutas exclusivas para Administradores */}
              <Route
                path="ruta-moviles"
                element={
                  <ProtectedRoute roles={['Administrador']}>
                    <RutaMoviles />
                  </ProtectedRoute>
                }
              />
              <Route
                path="enviar-mensajes"
                element={
                  <ProtectedRoute roles={['Administrador']}>
                    <EnviarMensajesTelegram />
                  </ProtectedRoute>
                }
              />
              <Route
                path="gestion-usuarios"
                element={
                  <ProtectedRoute roles={['Administrador']}>
                    <GestionUsuarios />
                  </ProtectedRoute>
                }
              />

              {/* Rutas accesibles para Administradores y Supervisores */}
              <Route
                path="gps"
                element={
                  <ProtectedRoute roles={['Administrador', 'Supervisor']}>
                    <GPS />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="*" element={<div>404 - Página no encontrada</div>} />
          </Routes>
        </Router>
      </LoadScript>
    </DarkModeProvider>
  );
}

export default App;
