import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/login.css';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const customUrl = `https://pedidos.ayvgas.cl/auth/login.php?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

    try {
      const response = await fetch(customUrl);

      if (!response.ok) {
        setError('Error en la solicitud. Intenta más tarde.');
        return;
      }

      const data = await response.json();
      if (data.success) {
        alert(data.message || 'Inicio de sesión exitoso');

        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        if (data.role) {
          localStorage.setItem('role', data.role);
        }

        navigate('/welcome/pedidos');
      } else {
        setError(data.message || 'Credenciales incorrectas.');
      }
    } catch (err) {
      console.error('Error al conectar con el servidor:', err);
      setError('Error de conexión con el servidor.');
    }
  };

  return (
    <div className="login-container">
      <h2>Iniciar Sesión</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div>
          <label>Usuario:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Contraseña:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={(e) => setShowPassword(e.target.checked)}
              />
              Mostrar contraseña
            </label>
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Ingresar</button>
      </form>
    </div>
  );
}

export default LoginForm;
