import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import nuevoBotonImg from '../images/pngegg.png';
import editarImg from '../images/editar.png';
import eliminarImg from '../images/eliminar.png';
import cambiarEstadoImg from '../images/cambiar.png';
import mapaImg from '../images/mapa.png';
import telegramImg from '../images/telegram.webp';
import alarmSound from '../images/japanese-eas-alarm.mp3';
import '../styles/Pedidos.css';
import { useNavigate } from 'react-router-dom';
import MobileStatus from '../components/MobileStatus';

dayjs.extend(customParseFormat);

const GOOGLE_MAPS_API_KEY = 'AIzaSyADoYPO9Uw_rs9ZFF301cBVs7PO_GgAESU';

const opcionesProductos = [
  'Gas 15kg Normal',
  'Gas 45kg Normal',
  'Gas 5kg Normal',
  'Gas 11kg Normal',
  'Gas 15kg VMA',
];

const opcionesEstado = ['Despacho', 'Entregado', 'Pendiente', 'Anulado'];
const opcionesCiudad = ['Arica', 'Antofagasta'];
const opcionesOrigen = ['Call Center', 'DUO', 'RR SS', 'Willy'];

function generarNumeroPedido() {
  const randomNumber = Math.floor(100000 + Math.random() * 900000);
  return `90${randomNumber}`;
}

function Pedidos() {
  const navigate = useNavigate();

  // Estados
  const [pedidos, setPedidos] = useState([]);
  const [nuevaFila, setNuevaFila] = useState({
    campo_2: generarNumeroPedido(),
    campo_4: '',
    campo_7: '',
    campo_8: '',
    campo_9: '',
    campo_13: '',
    campo_14: '',
    campo_10: '',
    campo_11: '',
    campo_12: '',
    campo_6: 'Despacho',
    campo_yy: '',
  });
  const [contadorCampo1, setContadorCampo1] = useState(1);
  const [editando, setEditando] = useState(null);
  const [estaEditando, setEstaEditando] = useState(false);
  const [editandoEstado, setEditandoEstado] = useState(null);
  const [errores, setErrores] = useState('');

  // Ref para el input de dirección
  const inputDireccionRef = useRef(null);

  // Estados para popups y notificaciones
  const [showWhatsappPopup, setShowWhatsappPopup] = useState(false);
  const [currentWhatsappOrder, setCurrentWhatsappOrder] = useState(null);
  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  // Estado para pedidos notificados de WhatsApp
  const [notifiedWhatsappOrders, setNotifiedWhatsappOrders] = useState(() => {
    const saved = localStorage.getItem('notifiedWhatsappOrders');
    return saved ? JSON.parse(saved) : [];
  });

  // Contactos (móviles) del JSON
  const [contactos, setContactos] = useState([]);

  // Lógica del sonido de alarma
  useEffect(() => {
    audioRef.current = new Audio(alarmSound);
    audioRef.current.preload = 'auto';
  }, []);

  useEffect(() => {
    localStorage.setItem('notifiedWhatsappOrders', JSON.stringify(notifiedWhatsappOrders));
  }, [notifiedWhatsappOrders]);

  useEffect(() => {
    const fetchContactos = async () => {
      try {
        const response = await fetch('https://pedidos.ayvgas.cl/pedidos/contactos.json');
        if (!response.ok) throw new Error('Error al cargar contactos.json');
        const data = await response.json();
        setContactos(data);
      } catch (error) {
        console.error('Error al cargar contactos:', error);
      }
    };
    fetchContactos();
  }, []);

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!estaEditando) {
        actualizarSoloFila();
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [estaEditando]);

  useEffect(() => {
    pedidos.forEach(pedido => {
      if (
        pedido.campo_12 &&
        pedido.campo_12.toLowerCase() === 'whatsapp' &&
        !notifiedWhatsappOrders.includes(pedido.campo_2)
      ) {
        handleNewPedido(pedido);
      }
    });
  }, [pedidos, notifiedWhatsappOrders]);

  // Inicializamos Autocomplete comprobando que la librería 'places' esté cargada
  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      inicializarAutocomplete();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = () => {
        inicializarAutocomplete();
      };
      document.body.appendChild(script);
    }
  }, []);

  const inicializarAutocomplete = () => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      inputDireccionRef.current
    ) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputDireccionRef.current,
        {
          types: ['address'],
          componentRestrictions: { country: 'cl' },
        }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address && place.geometry) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const campo_geocode = `${lat},${lng}`;
          setNuevaFila(prev => ({
            ...prev,
            campo_7: place.formatted_address.toUpperCase(),
            latitud: lat,
            longitud: lng,
            campo_geocode: campo_geocode
          }));
        }
      });
    }
  };


  const handleEditChange = (e, index) => {
    const { name, value } = e.target;
    const nuevosPedidos = [...pedidos];
    nuevosPedidos[index] = { ...nuevosPedidos[index], [name]: value };
    setPedidos(nuevosPedidos);
  };

  const cargarDatos = async () => {
    try {
      const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
        fetch('https://pedidos.ayvgas.cl/pedidos/pedidos.json', { cache: 'no-store' }).then(res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos.json`);
          return res.json();
        }),
        fetch('https://pedidos.ayvgas.cl/pedidos/pedidos_antofagasta.json', { cache: 'no-store' }).then(res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos_antofagasta.json`);
          return res.json();
        }),
        fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then(res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedido_callcenter.json`);
          return res.json();
        }),
      ]);

      const pedidosAricaArray = Array.isArray(pedidosArica)
        ? pedidosArica
        : Object.keys(pedidosArica).map(key => pedidosArica[key]);
      const pedidosAntofagastaArray = Array.isArray(pedidosAntofagasta)
        ? pedidosAntofagasta
        : Object.keys(pedidosAntofagasta).map(key => pedidosAntofagasta[key]);
      const pedidosCallCenterArray = Array.isArray(pedidosCallCenter)
        ? pedidosCallCenter
        : Object.keys(pedidosCallCenter).map(key => pedidosCallCenter[key]);

      const pedidosAricaConCiudad = pedidosAricaArray.map(pedido => ({
        ...pedido,
        campo_yy: 'Arica',
        campo_12: pedido.campo_12 || 'DUO',
        origen: 'pedidos.json',
      }));

      const pedidosAntofagastaConCiudad = pedidosAntofagastaArray.map(pedido => ({
        ...pedido,
        campo_yy: 'Antofagasta',
        campo_12: pedido.campo_12 || 'Call Center',
        origen: 'pedidos_antofagasta.json',
      }));

      const pedidosCallCenterConCiudad = pedidosCallCenterArray.map(pedido => ({
        ...pedido,
        campo_yy: pedido.campo_yy || 'Arica',
        campo_12: pedido.campo_12 || 'DUO',
        origen: 'pedido_callcenter.json',
      }));

      const pedidosCombinados = [
        ...pedidosAricaConCiudad,
        ...pedidosAntofagastaConCiudad,
        ...pedidosCallCenterConCiudad,
      ];

      const pedidosUnicos = pedidosCombinados.reduce((acc, current) => {
        const x = acc.find(item => item.campo_2 === current.campo_2);
        return !x ? acc.concat([current]) : acc;
      }, []);

      const maxCampo1 = Math.max(...pedidosUnicos.map(p => p.campo_1), 0);
      setContadorCampo1(maxCampo1 + 1);
      setPedidos(pedidosUnicos);
    } catch (error) {
      console.error('Error al cargar los pedidos:', error);
    }
  };

  const actualizarSoloFila = async () => {
    try {
      const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
        fetch('https://pedidos.ayvgas.cl/pedidos/pedidos.json', { cache: 'no-store' }).then(async res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos.json`);
          return JSON.parse(await res.text());
        }),
        fetch('https://pedidos.ayvgas.cl/pedidos/pedidos_antofagasta.json', { cache: 'no-store' }).then(async res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos_antofagasta.json`);
          return JSON.parse(await res.text());
        }),
        fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then(async res => {
          if (!res.ok) throw new Error(`Error ${res.status} al cargar pedido_callcenter.json`);
          return JSON.parse(await res.text());
        }),
      ]);

      const pedidosAricaArray = Array.isArray(pedidosArica)
        ? pedidosArica
        : Object.keys(pedidosArica).map(key => pedidosArica[key]);
      const pedidosAntofagastaArray = Array.isArray(pedidosAntofagasta)
        ? pedidosAntofagasta
        : Object.keys(pedidosAntofagasta).map(key => pedidosAntofagasta[key]);
      const pedidosCallCenterArray = Array.isArray(pedidosCallCenter)
        ? pedidosCallCenter
        : Object.keys(pedidosCallCenter).map(key => pedidosCallCenter[key]);

      const pedidosAricaConCiudad = pedidosAricaArray.map(pedido => ({ ...pedido, campo_yy: 'Arica' }));
      const pedidosAntofagastaConCiudad = pedidosAntofagastaArray.map(pedido => ({ ...pedido, campo_yy: 'Antofagasta' }));
      const pedidosCallCenterConCiudad = pedidosCallCenterArray.map(pedido => ({
        ...pedido,
        campo_yy: pedido.campo_yy || 'Arica',
      }));

      const pedidosCombinados = [
        ...pedidosAricaConCiudad,
        ...pedidosAntofagastaConCiudad,
        ...pedidosCallCenterConCiudad,
      ];

      const pedidosUnicos = pedidosCombinados.reduce((acc, current) => {
        const x = acc.find(item => item.campo_2 === current.campo_2);
        return !x ? acc.concat([current]) : acc;
      }, []);
      setPedidos(pedidosUnicos);
    } catch (error) {
      console.error('Error al actualizar la fila del pedido:', error);
    }
  };

  const handleNewPedido = (pedido) => {
    if (pedido.campo_12 && pedido.campo_12.toLowerCase() === 'whatsapp') {
      setCurrentWhatsappOrder(pedido.campo_2);
      if (audioRef.current) {
        audioRef.current.loop = true;
        audioRef.current.play().catch(error => {
          console.error('Error al reproducir audio (requiere interacción):', error);
        });
        timeoutRef.current = setTimeout(() => {
          audioRef.current.pause();
        }, 30000);
      }
      setShowWhatsappPopup(true);
    }
  };

  const handleAcceptPopup = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowWhatsappPopup(false);
    if (currentWhatsappOrder) {
      setNotifiedWhatsappOrders(prev => [...prev, currentWhatsappOrder]);
      setCurrentWhatsappOrder(null);
    }
  };

  const guardarEnArchivo = async (pedido) => {
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/guardar_pedido.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pedido),
      });
      if (!response.ok) throw new Error('Error al guardar el pedido');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const actualizarPedido = async (pedido) => {
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/actualizar_pedido.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pedido),
      });
      if (!response.ok) throw new Error('Error al actualizar el pedido');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const enviarPedidoAHistorico = async (pedido) => {
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/mover_a_historico.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pedido),
      });
      if (!response.ok) throw new Error('Error al mover el pedido a histórico');
      console.log(`Pedido ${pedido.campo_2} movido a histórico`);
    } catch (error) {
      console.error('Error al mover el pedido a histórico:', error);
    }
  };

  const validarCampos = (fila) =>
    fila.campo_4 && fila.campo_7 && fila.campo_8 && fila.campo_11 && fila.campo_yy && fila.campo_12;

  const handleNuevaFilaChange = (e) => {
    const { name, value } = e.target;
    setNuevaFila(prev => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (typeof index === 'number' && index >= 0) {
        handleMovilBlur(index);
        if (editando === index) {
          handleGuardarEdicion(index);
        } else if (editandoEstado === index) {
          setEditandoEstado(null);
        }
      } else {
        guardarFila();
      }
    }
  };

  const handleMovilBlur = (index) => {
    const valorIngresado = pedidos[index].campo_11 || '';
    const coincidencias = contactos.filter(c =>
      c.movil.toLowerCase().includes(valorIngresado.toLowerCase())
    );
    if (coincidencias.length === 1) {
      const nuevosPedidos = [...pedidos];
      nuevosPedidos[index].campo_11 = coincidencias[0].movil;
      setPedidos(nuevosPedidos);
    }
  };

  const handleMovilBlurNuevaFila = () => {
    const valorIngresado = nuevaFila.campo_11 || '';
    const coincidencias = contactos.filter(c =>
      c.movil.toLowerCase().includes(valorIngresado.toLowerCase())
    );
    if (coincidencias.length === 1) {
      setNuevaFila(prev => ({ ...prev, campo_11: coincidencias[0].movil }));
    }
  };

  const guardarFila = async () => {
    if (!validarCampos(nuevaFila)) {
      setErrores('Por favor, completa todos los campos obligatorios.');
      return;
    }
    const nuevoPedido = {
      ...nuevaFila,
      campo_1: contadorCampo1,
      campo_5: dayjs().format('DD-MM-YYYY, HH:mm'),
      campo_7: nuevaFila.campo_7.toUpperCase(),
      // Si ya se obtuvieron en el Autocomplete se agregarán,
      // de lo contrario se pueden definir como vacíos o nulos.
      latitud: nuevaFila.latitud || '',
      longitud: nuevaFila.longitud || '',
      campo_geocode: nuevaFila.campo_geocode || '',
    };
    try {
      await guardarEnArchivo(nuevoPedido);
      await cargarDatos();
      setContadorCampo1(prev => prev + 1);
      setNuevaFila({
        campo_2: generarNumeroPedido(),
        campo_4: '',
        campo_7: '',
        campo_8: '',
        campo_9: '',
        campo_13: '',
        campo_14: '',
        campo_10: '',
        campo_11: '',
        campo_12: '',
        campo_6: 'Despacho',
        campo_yy: '',
        // Reiniciamos los nuevos campos también:
        latitud: '',
        longitud: '',
        campo_geocode: ''
      });
    } catch (error) {
      console.error('Error al guardar la fila:', error);
    }
  };
  

  const handleEditar = (index) => {
    setEditando(index);
    setEstaEditando(true);
  };

  const handleGuardarEdicion = async (index) => {
    if (!validarCampos(pedidos[index])) {
      setErrores('Por favor, completa todos los campos obligatorios.');
      return;
    }
    setErrores('');
    setEditando(null);
    setEstaEditando(false);
    const pedidoActualizado = { ...pedidos[index], origen: pedidos[index].origen };
    try {
      await actualizarPedido(pedidoActualizado);
      await cargarDatos();
    } catch (error) {
      console.error('Error al actualizar el pedido:', error);
    }
  };

  const handleEliminar = async (index) => {
    const pedidoAEliminar = pedidos[index];
    const confirmacion = window.confirm('¿Estás seguro de que deseas eliminar este pedido?');
    if (!confirmacion) return;
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/eliminar_pedido_activo.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ campo_2: pedidoAEliminar.campo_2 }),
      });
      if (response.ok) {
        setPedidos(prev => prev.filter((_, i) => i !== index));
      } else {
        throw new Error('Error al eliminar el pedido');
      }
    } catch (error) {
      console.error('Error al eliminar el pedido:', error);
    }
    await cargarDatos();
  };

  const handleCambiarEstado = (index) => {
    setEditandoEstado(index);
  };

  const handleEstadoChange = async (e, index) => {
    const nuevoEstado = e.target.value;
    const nuevosPedidos = [...pedidos];
    const pedidoModificado = { ...nuevosPedidos[index], campo_6: nuevoEstado };
    try {
      await actualizarPedido(pedidoModificado);
      if (nuevoEstado === 'Anulado' || nuevoEstado === 'Entregado') {
        const tiempoTotal = detenerTiempo(pedidoModificado.campo_5);
        pedidoModificado.campo_xx = tiempoTotal;
        await enviarPedidoAHistorico(pedidoModificado);
        if (pedidoModificado.campo_12 === 'Whatsapp') {
          console.log('Enviando notificación Whatsapp con payload:', pedidoModificado);
          const responseWhatsapp = await fetch('https://pedidos.ayvgas.cl/pedidos/notificar_whatsapp.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pedidoModificado),
          });
          if (!responseWhatsapp.ok) throw new Error('Error al notificar por Whatsapp');
          const resultWhatsapp = await responseWhatsapp.json();
          console.log('Notificación Whatsapp:', resultWhatsapp);
          if (pedidoModificado.chat_id) {
            try {
              const deleteResponse = await fetch('https://pedidos.ayvgas.cl/pedidos/gestionar_borrado_telegram.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  pedido: pedidoModificado.campo_2,
                  chat_id: pedidoModificado.chat_id,
                }),
              });
              const deleteResult = await deleteResponse.json();
              console.log('Borrado de mensaje en Telegram:', deleteResult);
            } catch (error) {
              console.error('Error al borrar el mensaje de Telegram:', error);
            }
          } else {
            console.warn('No se encontró chat_id en el pedido; no se podrá borrar el mensaje de Telegram');
          }
        }
      }
      await cargarDatos();
    } catch (error) {
      console.error('Error al cambiar el estado del pedido:', error);
    }
    setEditandoEstado(null);
  };

  const detenerTiempo = (fechaPedido) => {
    const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
    if (!fecha.isValid()) return '00:00:00';
    const ahora = dayjs();
    const diferenciaSegundos = ahora.diff(fecha, 'second');
    return formatearTiempo(diferenciaSegundos);
  };

  const calcularDiferenciaTiempo = (fechaPedido) => {
    const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
    if (!fecha.isValid()) return { diferenciaMinutos: 0, diferenciaSegundos: 0 };
    const ahora = dayjs();
    const diferenciaMinutos = ahora.diff(fecha, 'minute');
    const diferenciaSegundos = ahora.diff(fecha, 'second');
    return { diferenciaMinutos, diferenciaSegundos };
  };

  const formatearTiempo = (segundosTotales) => {
    const horas = Math.floor(segundosTotales / 3600);
    const minutos = Math.floor((segundosTotales % 3600) / 60);
    const segundos = segundosTotales % 60;
    return `${horas.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
  };

  const obtenerSemaforoYColor = (diferenciaMinutos) => {
    if (diferenciaMinutos < 6) return { imagen: img1, color: 'verde', nombre: 'Semáforo 1' };
    if (diferenciaMinutos < 12) return { imagen: img2, color: 'amarillo', nombre: 'Semáforo 2' };
    if (diferenciaMinutos < 18) return { imagen: img3, color: 'naranja', nombre: 'Semáforo 3' };
    if (diferenciaMinutos < 24) return { imagen: img4, color: 'rojo', nombre: 'Semáforo 4' };
    return { imagen: img5, color: 'morado', nombre: 'Semáforo 5' };
  };

  const handleEnviarTelegram = async (index) => {
    const pedido = pedidos[index];
    const movil = pedido.campo_11;
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/contactos.json');
      if (!response.ok) throw new Error('Error al cargar los contactos');
      const contactosJson = await response.json();
      const contacto = contactosJson.find(c => c.movil === movil);
      if (!contacto) {
        alert(`No se encontró el chat_id_telegram para este móvil: ${movil}`);
        return;
      }
      const chatIdTelegram = contacto.chat_id_telegram;
      const mensaje = [
        `*Pedido ${pedido.campo_2}:*`,
        `*Teléfono del Cliente:* ${pedido.campo_4 || 'No definido'}`,
        `*Dirección:* ${pedido.campo_7 || 'No definida'}`,
        '',
        pedido.campo_8 ? `*Producto1:* ${pedido.campo_8}` : '',
        pedido.campo_9 ? `*Producto2:* ${pedido.campo_9}` : '',
        pedido.campo_13 ? `*Producto3:* ${pedido.campo_13}` : '',
        pedido.campo_14 ? `*Producto4:* ${pedido.campo_14}` : '',
        '',
        `*Estado:* ${pedido.campo_6 || 'No definido'}`,
        `*Ciudad:* ${pedido.campo_yy || 'No definida'}`,
      ]
        .filter(line => line)
        .join('\n');

      const tecladoEnLinea = {
        inline_keyboard: [
          [
            {
              text: '📞 Contactar al Cliente',
              url: `https://pedidos.ayvgas.cl/llamar.html?telefono=${encodeURIComponent(pedido.campo_4)}`,
            },
          ],
        ],
      };

      const TELEGRAM_BOT_TOKEN = '7463925088:AAHPDr0alFLRsp_2nAr429Oe-AzinIRKPqo';
      const urlTelegram = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

      const sendMessageResponse = await fetch(urlTelegram, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: chatIdTelegram,
          text: mensaje,
          parse_mode: 'Markdown',
          reply_markup: tecladoEnLinea,
        }),
      });

      const result = await sendMessageResponse.json();
      console.log('Telegram API Response:', result);
      if (!sendMessageResponse.ok) {
        console.error('Error al enviar el mensaje de Telegram:', result);
        throw new Error(result.description || 'Error al enviar el mensaje');
      }
      alert('Mensaje enviado correctamente a Telegram');
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.message);
      alert(`Hubo un error al enviar el mensaje: ${error.message}`);
    }
  };

  const handleNuevoBoton = (index) => {
    console.log(`Nuevo botón clicado en el índice: ${index}`);
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPedidos(prevPedidos =>
        prevPedidos.map(pedido => {
          const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
          return {
            ...pedido,
            tiempoFormateado: formatearTiempo(diferenciaSegundos),
            semaforoImagen: obtenerSemaforoYColor(diferenciaMinutos).imagen,
          };
        })
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="pedidos-container">
      <header>
        <MobileStatus />
      </header>
      <h1>Pedidos Activos</h1>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>N° del Pedido</th>
              <th>Semáforo</th>
              <th>Teléfono</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Dirección</th>
              <th>Producto1</th>
              <th>Producto2</th>
              <th>Producto3</th>
              <th>Producto4</th>
              <th>Movil</th>
              <th>Origen</th>
              <th>Ciudad</th>
              <th>Tiempo</th>
            </tr>
          </thead>
          <tbody>
            {pedidos.map((pedido, index) => {
              const { diferenciaMinutos } = calcularDiferenciaTiempo(pedido.campo_5);
              const { imagen: imagenSemaforo } = obtenerSemaforoYColor(diferenciaMinutos);
              return (
                <tr key={index}>
                  <td>{pedido.campo_1}</td>
                  <td>{pedido.campo_2}</td>
                  <td>
                    <img src={imagenSemaforo} alt="Semáforo" width="50" />
                  </td>
                  <td>
                    {editando === index ? (
                      <input
                        type="text"
                        name="campo_4"
                        value={pedido.campo_4}
                        onChange={(e) => handleEditChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        autoComplete="off"
                      />
                    ) : (
                      pedido.campo_4
                    )}
                  </td>
                  <td>{pedido.campo_5}</td>
                  <td>
                    {editandoEstado === index ? (
                      <select
                        value={pedido.campo_6}
                        onChange={(e) => handleEstadoChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      >
                        {opcionesEstado.map((estado) => (
                          <option key={estado} value={estado}>
                            {estado}
                          </option>
                        ))}
                      </select>
                    ) : (
                      pedido.campo_6
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <input
                        type="text"
                        name="campo_7"
                        value={pedido.campo_7}
                        onChange={(e) => handleEditChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        autoComplete="off"
                      />
                    ) : (
                      pedido.campo_7
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select name="campo_8" value={pedido.campo_8} onChange={(e) => handleEditChange(e, index)}>
                        <option value="">Seleccionar</option>
                        {opcionesProductos.map((producto) => (
                          <option key={producto} value={producto}>
                            {producto}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{pedido.campo_8}</span>
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select name="campo_9" value={pedido.campo_9} onChange={(e) => handleEditChange(e, index)}>
                        <option value="">Seleccionar</option>
                        {opcionesProductos.map((producto) => (
                          <option key={producto} value={producto}>
                            {producto}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{pedido.campo_9}</span>
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select name="campo_13" value={pedido.campo_13} onChange={(e) => handleEditChange(e, index)}>
                        <option value="">Seleccionar</option>
                        {opcionesProductos.map((producto) => (
                          <option key={producto} value={producto}>
                            {producto}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{pedido.campo_13}</span>
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select name="campo_14" value={pedido.campo_14} onChange={(e) => handleEditChange(e, index)}>
                        <option value="">Seleccionar</option>
                        {opcionesProductos.map((producto) => (
                          <option key={producto} value={producto}>
                            {producto}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{pedido.campo_14}</span>
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <>
                        <input
                          type="text"
                          name="campo_11"
                          value={pedido.campo_11}
                          onChange={(e) => handleEditChange(e, index)}
                          onBlur={() => handleMovilBlur(index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          list={`movilOptions-${index}`}
                          autoComplete="off"
                        />
                        <datalist id={`movilOptions-${index}`}>
                          {contactos
                            .filter(c =>
                              c.movil.toLowerCase().includes((pedido.campo_11 || "").toLowerCase())
                            )
                            .map(contacto => (
                              <option key={contacto.movil} value={contacto.movil} />
                            ))}
                        </datalist>
                      </>
                    ) : (
                      pedido.campo_11
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select
                        name="campo_12"
                        value={pedido.campo_12}
                        onChange={(e) => handleEditChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      >
                        <option value="">Seleccionar Origen</option>
                        {opcionesOrigen.map((origen) => (
                          <option key={origen} value={origen}>
                            {origen}
                          </option>
                        ))}
                      </select>
                    ) : (
                      pedido.campo_12
                    )}
                  </td>
                  <td>
                    {editando === index ? (
                      <select
                        name="campo_yy"
                        value={pedido.campo_yy}
                        onChange={(e) => handleEditChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      >
                        <option value="">Seleccionar Ciudad</option>
                        {opcionesCiudad.map((ciudad) => (
                          <option key={ciudad} value={ciudad}>
                            {ciudad}
                          </option>
                        ))}
                      </select>
                    ) : (
                      pedido.campo_yy
                    )}
                  </td>
                  <td>{pedido.tiempoFormateado}</td>
                  <td style={{ display: "flex", gap: "5px" }}>
                    {editando === index ? (
                      <img
                        src={editarImg}
                        alt="Guardar"
                        width="30"
                        onClick={async () => {
                          await handleGuardarEdicion(index);
                        }}
                      />
                    ) : (
                      <img
                        src={editarImg}
                        alt="Editar"
                        width="30"
                        onClick={() => handleEditar(index)}
                      />
                    )}
                    <img
                      src={eliminarImg}
                      alt="Eliminar"
                      width="30"
                      onClick={async () => {
                        await handleEliminar(index);
                      }}
                    />
                    <img
                      src={cambiarEstadoImg}
                      alt="Cambiar Estado"
                      width="30"
                      onClick={() => {
                        if (editandoEstado === index) {
                          setEditandoEstado(null);
                        } else {
                          handleCambiarEstado(index);
                        }
                      }}
                    />
                    <img
                      src={telegramImg}
                      alt="Telegram"
                      width="30"
                      onClick={() => handleEnviarTelegram(index)}
                    />
                    <img
                      src={nuevoBotonImg}
                      alt="Mapa"
                      width="30"
                      onClick={() => navigate(`/welcome/mapa/${pedido.campo_yy}`)}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>{contadorCampo1}</td>
              <td>{nuevaFila.campo_2}</td>
              <td></td>
              <td>
                <input
                  type="text"
                  name="campo_4"
                  value={nuevaFila.campo_4}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  autoComplete="off"
                />
              </td>
              <td></td>
              <td>Despacho</td>
              <td>
                <input
                  ref={inputDireccionRef}
                  type="text"
                  name="campo_7"
                  value={nuevaFila.campo_7}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  autoComplete="off"
                />
              </td>
              <td>
                <select
                  name="campo_8"
                  value={nuevaFila.campo_8}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e, -1)}
                >
                  <option value="">Seleccionar</option>
                  {opcionesProductos.map((producto) => (
                    <option key={producto} value={producto}>
                      {producto}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="campo_9"
                  value={nuevaFila.campo_9}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e, -1)}
                >
                  <option value="">Seleccionar</option>
                  {opcionesProductos.map((producto) => (
                    <option key={producto} value={producto}>
                      {producto}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="campo_13"
                  value={nuevaFila.campo_13}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e, -1)}
                >
                  <option value="">Seleccionar</option>
                  {opcionesProductos.map((producto) => (
                    <option key={producto} value={producto}>
                      {producto}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="campo_14"
                  value={nuevaFila.campo_14}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e, -1)}
                >
                  <option value="">Seleccionar</option>
                  {opcionesProductos.map((producto) => (
                    <option key={producto} value={producto}>
                      {producto}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  list="movilOptions-new"
                  name="campo_11"
                  value={nuevaFila.campo_11}
                  onChange={handleNuevaFilaChange}
                  onBlur={handleMovilBlurNuevaFila}
                  onKeyDown={(e) => handleKeyDown(e)}
                  autoComplete="off"
                />
                <datalist id="movilOptions-new">
                  {contactos
                    .filter(c =>
                      c.movil.toLowerCase().includes((nuevaFila.campo_11 || "").toLowerCase())
                    )
                    .map(contacto => (
                      <option key={contacto.movil} value={contacto.movil} />
                    ))}
                </datalist>
              </td>
              <td>
                <select
                  name="campo_12"
                  value={nuevaFila.campo_12}
                  onChange={handleNuevaFilaChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                >
                  <option value="">Seleccionar Origen</option>
                  {opcionesOrigen.map((origen) => (
                    <option key={origen} value={origen}>
                      {origen}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="campo_yy"
                  value={nuevaFila.campo_yy}
                  onChange={handleNuevaFilaChange}
                  required
                >
                  <option value="">Seleccionar Ciudad</option>
                  {opcionesCiudad.map((ciudad) => (
                    <option key={ciudad} value={ciudad}>
                      {ciudad}
                    </option>
                  ))}
                </select>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {showWhatsappPopup && (
          <div className="popup">
            <p>Pedido de WhatsApp entrante: {currentWhatsappOrder}</p>
            <button className="popup-button" onClick={handleAcceptPopup}>
              Aceptar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pedidos;
