import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Tooltip,
  useMap,
  CircleMarker,
  Circle
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/RutaMoviles.css";

// ==================== VARIABLES GLOBALES ====================

// Paleta de colores para diferenciar móviles
const colorPalette = [
  "#FF5733",
  "#337BFF",
  "#33FF57",
  "#FF33A1",
  "#FFC300",
  "#8E44AD",
  "#16A085",
  "#D35400",
  "#C0392B",
  "#2C3E50",
];

// Icono por defecto (para markers de inicio y fin)
const defaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-icon.png",
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-icon-2x.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-shadow.png",
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

// Icono de casa (se usará un único marker en el centro del polígono)
const houseIcon = L.divIcon({
  html: `<span style="font-size: 2rem;">🏠</span>`,
  className: "house-icon",
  iconSize: [40, 40],
  iconAnchor: [20, 20],
});

// Colores para antenas según empresa
const antennaColors = {
  WOM: "#800080",      // morado
  CLARO: "#FF0000",    // rojo
  ENTEL: "#0000FF",    // azul
  MOVISTAR: "#00CED1", // calipso
  OTRA: "#808080",     // para otras
};

// ==================== FUNCIONES DE AYUDA ====================

// Parsea la fecha en formato "dd-mm-yyyy hh:mm:ss" a Date
function parseLastReport(str) {
  const [datePart, timePart] = str.split(" ");
  const [day, month, year] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
}

// Calcula la distancia en metros entre dos puntos (Leaflet usa [lat, lng])
function getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
  const R = 6371000;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

// Retorna el centro de un array de coordenadas [lat, lng]
function getCenterOfCoords(coords) {
  if (coords.length === 0) return null;
  let sumLat = 0,
    sumLon = 0;
  coords.forEach(([lat, lng]) => {
    sumLat += lat;
    sumLon += lng;
  });
  return [sumLat / coords.length, sumLon / coords.length];
}

// GeoJSON de zonas "Casa" para cada móvil (clave debe coincidir con el móvil)
const casaGeoJSON = {
  M50: [
    [-70.38410219793484, -23.579223898377734],
    [-70.38402571355262, -23.58037096031886],
    [-70.38188762741292, -23.580256254576],
    [-70.38200930711172, -23.579077328629737],
    [-70.3841056744977, -23.579223898377734],
  ],
  M61: [
    [-70.39466782023563, -23.66607065785516],
    [-70.39458044988294, -23.668164564372447],
    [-70.39065606487627, -23.668044532421177],
    [-70.39077255868006, -23.66592394977185],
    [-70.39467510109841, -23.66607732640051],
  ],
  M62: [
    [-70.38384415477127, -23.566383042897527],
    [-70.38364030688555, -23.56868418434732],
    [-70.38079716533011, -23.56844817169501],
    [-70.38115121692002, -23.565920842958192],
    [-70.38383342597855, -23.56634370682569],
  ],
};

// Retorna el centro de un polígono (formato [[lon, lat], ...])
function getCenterOfPolygon(polygon) {
  if (!polygon || polygon.length === 0) return null;
  let sumLat = 0,
    sumLon = 0;
  polygon.forEach(([lon, lat]) => {
    sumLon += lon;
    sumLat += lat;
  });
  return [sumLat / polygon.length, sumLon / polygon.length];
}

// Verifica si un punto (con properties latitude y longitude) está dentro de un polígono
function isPointInPolygon(point, vs) {
  const x = point.longitude,
    y = point.latitude;
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

// Determina si un punto está en la zona excluida (por ejemplo, dentro del local)
function isExcluded(point) {
  const excludedCenter = { latitude: -23.5526968, longitude: -70.3909427 };
  const dist = getDistanceFromLatLonInM(
    point.latitude,
    point.longitude,
    excludedCenter.latitude,
    excludedCenter.longitude
  );
  return dist <= 100;
}

// Determina si un punto está en la "Casa" del móvil (según el geoJSON)
function isInCasa(point, movil) {
  const polygon = casaGeoJSON[movil];
  if (!polygon) return false;
  return isPointInPolygon(point, polygon);
}

// Busca el lastReport del registro cuyo punto esté más cercano a [lat, lon]
function findNearestTime(originalData, lat, lon, lowerBound = null, upperBound = null) {
  let minDist = Infinity;
  let nearestTime = null;
  originalData.forEach((point) => {
    const pointTime = parseLastReport(point.lastReport);
    if (lowerBound && pointTime < lowerBound) return;
    if (upperBound && pointTime > upperBound) return;
    const dist = getDistanceFromLatLonInM(lat, lon, point.latitude, point.longitude);
    if (dist < minDist) {
      minDist = dist;
      nearestTime = point.lastReport;
    }
  });
  return nearestTime;
}

// Busca el registro completo (punto) más cercano a [lat, lon]
function findNearestPoint(originalData, lat, lon) {
  let minDist = Infinity;
  let nearest = null;
  originalData.forEach((point) => {
    const dist = getDistanceFromLatLonInM(lat, lon, point.latitude, point.longitude);
    if (dist < minDist) {
      minDist = dist;
      nearest = point;
    }
  });
  return nearest;
}

// Filtra registros para el horario de 8:00 am a 9:00 pm
function filterBySchedule(originalData) {
  return originalData.filter((point) => {
    const time = parseLastReport(point.lastReport);
    const startSchedule = new Date(time);
    startSchedule.setHours(8, 0, 0, 0);
    const endSchedule = new Date(time);
    endSchedule.setHours(21, 0, 0, 0);
    return time >= startSchedule && time <= endSchedule;
  });
}

// Filtra puntos muy cercanos para evitar duplicados
function filterClosePoints(coords) {
  const threshold = 0.0001;
  return coords.filter((point, index, array) => {
    if (index === 0) return true;
    const prev = array[index - 1];
    const latDiff = Math.abs(point[0] - prev[0]);
    const lonDiff = Math.abs(point[1] - prev[1]);
    return latDiff > threshold || lonDiff > threshold;
  });
}

// Calcula la distancia total de una ruta sumando las distancias entre cada par consecutivo de puntos
function calculateRouteDistance(coords) {
  let total = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    total += getDistanceFromLatLonInM(
      coords[i][0],
      coords[i][1],
      coords[i + 1][0],
      coords[i + 1][1]
    );
  }
  return total;
}

// Identifica segmentos detenidos (detenciones generales)
function identifyStoppedSegments(data) {
  const distanceThreshold = 100;
  const timeThreshold = 300; // 5 minutos
  const minorThreshold = 790; // menos de 13 minutos
  let segments = [];
  let currentGroup = [];
  const sortedData = [...data].sort(
    (a, b) => parseLastReport(a.lastReport).getTime() - parseLastReport(b.lastReport).getTime()
  );
  for (let i = 0; i < sortedData.length; i++) {
    const point = sortedData[i];
    if (isExcluded(point)) {
      if (currentGroup.length > 1) {
        const startTime = parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
        const endTime = parseLastReport(currentGroup[currentGroup.length - 1].lastReport).getTime() / 1000;
        const duration = endTime - startTime;
        if (duration >= timeThreshold) {
          segments.push({
            coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
            stopDurationSec: duration,
            minor: duration < minorThreshold,
          });
        }
      }
      currentGroup = [];
      continue;
    }
    if (currentGroup.length === 0) {
      currentGroup.push(point);
    } else {
      const lastPoint = currentGroup[currentGroup.length - 1];
      const dist = getDistanceFromLatLonInM(
        lastPoint.latitude,
        lastPoint.longitude,
        point.latitude,
        point.longitude
      );
      if (dist <= distanceThreshold) {
        currentGroup.push(point);
      } else {
        if (currentGroup.length > 1) {
          const startTime = parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
          const endTime = parseLastReport(currentGroup[currentGroup.length - 1].lastReport).getTime() / 1000;
          const duration = endTime - startTime;
          if (duration >= timeThreshold) {
            segments.push({
              coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
              stopDurationSec: duration,
              minor: duration < minorThreshold,
            });
          }
        }
        currentGroup = [point];
      }
    }
  }
  if (currentGroup.length > 1) {
    const startTime = parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
    const endTime = parseLastReport(currentGroup[currentGroup.length - 1].lastReport).getTime() / 1000;
    const duration = endTime - startTime;
    if (duration >= timeThreshold) {
      segments.push({
        coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
        stopDurationSec: duration,
        minor: duration < minorThreshold,
      });
    }
  }
  const totalStopTimeSec = segments.reduce((acc, seg) => acc + seg.stopDurationSec, 0);
  const stopTimeTotal = Math.floor(totalStopTimeSec / 60);
  return { stopTimeTotal, stopTimeSec: totalStopTimeSec, segments };
}

// Identifica detenciones en Casa
function identifyCasaStops(data) {
  if (!data || data.length === 0) return [];
  let stops = [];
  let currentGroup = [];
  const sortedData = [...data].sort(
    (a, b) => parseLastReport(a.lastReport).getTime() - parseLastReport(b.lastReport).getTime()
  );
  for (let i = 0; i < sortedData.length; i++) {
    const point = sortedData[i];
    if (currentGroup.length === 0) {
      currentGroup.push(point);
    } else {
      const lastPoint = currentGroup[currentGroup.length - 1];
      const dist = getDistanceFromLatLonInM(
        lastPoint.latitude,
        lastPoint.longitude,
        point.latitude,
        point.longitude
      );
      const timeDiff =
        (parseLastReport(point.lastReport).getTime() - parseLastReport(lastPoint.lastReport).getTime()) / 1000;
      if (dist <= 100 && timeDiff <= 7200) {
        currentGroup.push(point);
      } else {
        const startTime = parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
        const endTime = parseLastReport(currentGroup[currentGroup.length - 1].lastReport).getTime() / 1000;
        stops.push({
          coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
          stopDurationSec: endTime - startTime,
          start: currentGroup[0].lastReport,
          end: currentGroup[currentGroup.length - 1].lastReport,
        });
        currentGroup = [point];
      }
    }
  }
  if (currentGroup.length > 0) {
    const startTime = parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
    const endTime = parseLastReport(currentGroup[currentGroup.length - 1].lastReport).getTime() / 1000;
    stops.push({
      coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
      stopDurationSec: endTime - startTime,
      start: currentGroup[0].lastReport,
      end: currentGroup[currentGroup.length - 1].lastReport,
    });
  }
  return stops;
}

// Función auxiliar para obtener la empresa de la antena
function getAntennaCompany(empresa) {
  if (!empresa) return "OTRA";
  const emp = empresa.toUpperCase().trim();
  if (emp.includes("WOM")) return "WOM";
  if (emp.includes("CLARO")) return "CLARO";
  if (emp.includes("ENTEL")) return "ENTEL";
  if (emp.includes("MOVISTAR")) return "MOVISTAR";
  return "OTRA";
}

// ==================== FIN FUNCIONES DE AYUDA ====================

// Componente para reposicionar el mapa
function ChangeMapView({ center, zoom }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
}

// ==================== COMPONENTE RutaMoviles ====================
const RutaMoviles = () => {
  const [routes, setRoutes] = useState([]);
  const [progress, setProgress] = useState(100);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalFuel, setTotalFuel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filtros
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [timeOption, setTimeOption] = useState("allDay");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [availableMoviles, setAvailableMoviles] = useState([]);
  const [selectedMoviles, setSelectedMoviles] = useState([]);

  // Mapa
  const [selectedCity, setSelectedCity] = useState("Antofagasta");
  const [mapCenter, setMapCenter] = useState([-23.62, -70.39]);
  const [mapZoom, setMapZoom] = useState(12);

  // Estados para marker personalizado (múltiples coordenadas)
  const [customMarkerInput, setCustomMarkerInput] = useState("");
  const [customMarkers, setCustomMarkers] = useState([]);

  // Estados para antenas
  const [antennas, setAntennas] = useState([]);
  const [showAntennas, setShowAntennas] = useState(false);
  const [antennaFilters, setAntennaFilters] = useState({});

  useEffect(() => {
    const fetchMoviles = async () => {
      try {
        const response = await fetch("https://pedidos.ayvgas.cl/ubicaciones/get_json_files.php");
        if (!response.ok) throw new Error("Error al cargar los móviles.");
        const data = await response.json();
        const mobiles = Array.isArray(data) ? data : Object.values(data);
        setAvailableMoviles(mobiles);
      } catch (error) {
        console.error("Error al obtener los móviles:", error);
      }
    };
    fetchMoviles();
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedMoviles((prev) => [...prev, value]);
    } else {
      setSelectedMoviles((prev) => prev.filter((m) => m !== value));
    }
  };

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const toggleVisibility = (mobile) => {
    setRoutes((prevRoutes) =>
      prevRoutes.map((route) =>
        route.mobile === mobile ? { ...route, visible: !route.visible } : route
      )
    );
  };

  // Retorna la porción de la ruta según la barra de progreso
  const getPartialCoords = (decodedCoords) => {
    if (progress >= 100) return decodedCoords;
    const numPoints = Math.floor((decodedCoords.length * progress) / 100);
    return numPoints < 1 ? [decodedCoords[0]] : decodedCoords.slice(0, numPoints);
  };

  // Muestra la hora del punto final del tramo visible (filtrando por horario 8:00 - 21:00)
  const currentTimeDisplay = () => {
    if (routes.length === 0) return "";
    const routeObj = routes[0];
    const partialCoords = getPartialCoords(routeObj.decodedCoords);
    if (partialCoords.length === 0) return "";
    const filteredData = filterBySchedule(routeObj.originalData);
    const [lat, lon] = partialCoords[partialCoords.length - 1];
    const nearestTime = findNearestTime(filteredData, lat, lon);
    if (!nearestTime) return "";
    const dateObj = parseLastReport(nearestTime);
    return dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  // Función para cargar marker personalizado (múltiples coordenadas)
  const handleLoadMarker = () => {
    const parts = customMarkerInput.split(",");
    if (parts.length !== 2) {
      alert("Por favor, ingresa las coordenadas en el formato correcto: lat,lng");
      return;
    }
    const lat = parseFloat(parts[0].trim());
    const lng = parseFloat(parts[1].trim());
    if (isNaN(lat) || isNaN(lng)) {
      alert("Las coordenadas ingresadas no son válidas.");
      return;
    }
    const newMarker = [lat, lng];
    setCustomMarkers((prev) => [...prev, newMarker]);
    setCustomMarkerInput("");
    // Opcional: centrar el mapa en la coordenada recién cargada
    setMapCenter([lat, lng]);
    setMapZoom(14);
  };

  // Función para cargar antenas según ciudad y extraer las empresas únicas
  const handleLoadAntennas = async () => {
    try {
      const url =
        selectedCity === "Arica"
          ? "https://pedidos.ayvgas.cl/antenas-arica.json"
          : "https://pedidos.ayvgas.cl/antenas-antofagasta.json";
      const response = await fetch(url);
      if (!response.ok) throw new Error("Error al cargar antenas.");
      const data = await response.json();
      // Si el JSON tiene la propiedad "registros", usarla
      const antennasData = data.registros
        ? data.registros
        : Array.isArray(data)
        ? data
        : Object.values(data);
      setAntennas(antennasData);
      setShowAntennas(true);
      // Extraer las empresas únicas
      const uniqueCompanies = [
        ...new Set(antennasData.map((antena) => getAntennaCompany(antena.Empresa))),
      ];
      const filters = {};
      uniqueCompanies.forEach((comp) => {
        filters[comp] = true;
      });
      setAntennaFilters(filters);
    } catch (error) {
      console.error("Error al cargar antenas:", error);
    }
  };

  // Maneja el cambio de filtro para antenas
  const handleAntennaFilterChange = (e) => {
    const { name, checked } = e.target;
    setAntennaFilters((prev) => ({ ...prev, [name]: checked }));
  };

  // Lógica para filtrar datos y construir rutas
  const handleFilter = async () => {
    try {
      if (selectedMoviles.length === 0) {
        alert("Por favor, selecciona al menos un móvil.");
        return;
      }
      setLoading(true);
      setError(null);
      setRoutes([]);
      setProgress(100);
      setTotalDistance(0);
      setTotalFuel(0);

      let newRoutes = [];
      let colorIndex = 0;
      let distanceSum = 0;

      const timeParams =
        timeOption === "allDay"
          ? { startTime: "00:00", endTime: "23:59" }
          : { startTime, endTime };
      const startDateStr = formatLocalDate(startDate);
      const endDateStr = formatLocalDate(endDate);

      for (const movilSeleccionado of selectedMoviles) {
        const body = {
          movil: movilSeleccionado,
          startDate: startDateStr,
          endDate: endDateStr,
          timeOption,
          ...timeParams,
        };

        console.log("Enviando al PHP:", body);

        const response = await fetch("https://pedidos.ayvgas.cl/ubicaciones/get_ruta_filtrada.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        });
        if (!response.ok) throw new Error(`Error HTTP PHP: ${response.status}`);
        const filteredData = await response.json();
        console.log("Móvil:", movilSeleccionado, "Registros:", filteredData);
        if (filteredData.length < 2) {
          console.warn(`No hay suficientes registros para el móvil ${movilSeleccionado}`);
          continue;
        }

        // Ordenar por fecha ascendente
        const sortedData = [...filteredData].sort(
          (a, b) =>
            parseLastReport(a.lastReport).getTime() - parseLastReport(b.lastReport).getTime()
        );
        const timestamps = sortedData.map((point) => point.lastReport);

        // Calcular horarios de salida y entrada (excluyendo zona)
        let earliestExit = null;
        for (let i = 0; i < sortedData.length; i++) {
          if (!isExcluded(sortedData[i])) {
            earliestExit = parseLastReport(sortedData[i].lastReport);
            break;
          }
        }
        let latestEntry = null;
        for (let i = 1; i < sortedData.length; i++) {
          const prevExcluded = isExcluded(sortedData[i - 1]);
          const currentExcluded = isExcluded(sortedData[i]);
          if (!prevExcluded && currentExcluded) {
            const entryTime = parseLastReport(sortedData[i].lastReport);
            if (!latestEntry || entryTime > latestEntry) {
              latestEntry = entryTime;
            }
          }
        }

        // Cálculos de tiempos
        let totalTimeSecAdjusted = 0;
        for (let i = 0; i < sortedData.length - 1; i++) {
          const gap =
            (parseLastReport(sortedData[i + 1].lastReport).getTime() -
              parseLastReport(sortedData[i].lastReport).getTime()) / 1000;
          if (gap < 7200) {
            totalTimeSecAdjusted += gap;
          }
        }
        let excludedTimeSecAdjusted = 0;
        for (let i = 0; i < sortedData.length - 1; i++) {
          const gap =
            (parseLastReport(sortedData[i + 1].lastReport).getTime() -
              parseLastReport(sortedData[i].lastReport).getTime()) / 1000;
          if (gap < 7200 && isExcluded(sortedData[i]) && isExcluded(sortedData[i + 1])) {
            excludedTimeSecAdjusted += gap;
          }
        }

        // Identificar detenciones generales y en casa
        const generalData = filteredData.filter((d) => !isInCasa(d, movilSeleccionado));
        const { stopTimeTotal, stopTimeSec, segments } = identifyStoppedSegments(generalData);
        const casaData = filteredData.filter((d) => isInCasa(d, movilSeleccionado));
        const casaStops = identifyCasaStops(casaData);
        const casaTotalTimeSec = casaStops.reduce((sum, stop) => sum + stop.stopDurationSec, 0);
        const casaTotal = Math.floor(casaTotalTimeSec / 60);
        const activeTimeSec = totalTimeSecAdjusted - excludedTimeSecAdjusted - (stopTimeSec + casaTotalTimeSec);
        const activeTime = Math.floor(activeTimeSec / 60);

        // Construir la ruta usando las coordenadas del JSON (orden: [lat, lng])
        const routeCoords = sortedData.map((d) => [d.latitude, d.longitude]);
        const routeDistance = calculateRouteDistance(routeCoords);
        distanceSum += routeDistance;

        // Calcular el centro de la zona "Casa" usando el geoJSON correspondiente
        const casaCoord = casaTotal > 0 ? getCenterOfPolygon(casaGeoJSON[movilSeleccionado]) : null;

        newRoutes.push({
          mobile: movilSeleccionado,
          color: colorPalette[colorIndex % colorPalette.length],
          distanceKm: (routeDistance / 1000).toFixed(2),
          decodedCoords: routeCoords,
          timestamps,
          originalData: sortedData,
          visible: true,
          stopTime: stopTimeTotal,
          casa: casaTotal,
          casaCoord,
          activeTime,
          exitTime: earliestExit,
          entryTime: latestEntry,
          // Guardar los segmentos detenidos (general)
          stoppedSegments: segments,
        });
        colorIndex++;
      }

      setRoutes(newRoutes);
      setTotalDistance((distanceSum / 1000).toFixed(2));
      setTotalFuel(((distanceSum / 1000) / 7.4).toFixed(2));
      setLoading(false);

      // Ajuste de mapa según ciudad
      if (selectedCity === "Arica") {
        setMapCenter([-18.4783, -70.3126]);
        setMapZoom(13);
      } else {
        setMapCenter([-23.62, -70.39]);
        setMapZoom(12);
      }
    } catch (err) {
      console.error("Error en handleFilter:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      {/* Panel de Filtros */}
      <div
        className="filter-panel"
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 20px)",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>{`
          .filter-panel::-webkit-scrollbar {
            display: none;
          }
        `}</style>
        <h4>Filtros</h4>

        {/* Filtro de Ciudad */}
        <div className="filter-group filter-city-group">
          <label>Ciudad:</label>
          <select
            value={selectedCity}
            onChange={(e) => {
              const city = e.target.value;
              setSelectedCity(city);
              if (city === "Arica") {
                setMapCenter([-18.4783, -70.3126]);
                setMapZoom(13);
              } else {
                setMapCenter([-23.62, -70.39]);
                setMapZoom(12);
              }
            }}
            className="input city-select"
          >
            <option value="Antofagasta">Antofagasta</option>
            <option value="Arica">Arica</option>
          </select>
        </div>

        {/* Filtro de Fecha */}
        <div className="filter-group filter-date-group">
          <label>Fecha:</label>
          <div className="date-picker-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="input date-input"
              withPortal
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="input date-input"
              withPortal
            />
          </div>
        </div>

        {/* Filtro de Hora */}
        <div className="filter-group filter-hour-group">
          <label>Hora:</label>
          <select
            value={timeOption}
            onChange={(e) => setTimeOption(e.target.value)}
            className="input hour-select"
          >
            <option value="allDay">Todo el día</option>
            <option value="range">Rango Específico</option>
          </select>
          {timeOption === "range" && (
            <div className="time-group hour-range-group">
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="input time-input start-time-input"
              />
              <span className="time-separator"> - </span>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="input time-input end-time-input"
              />
            </div>
          )}
        </div>

        {/* Filtro de Móviles */}
        <div className="filter-group filter-mobiles-group">
          <label>Móviles:</label>
          <div
            className="mobiles-checkbox-group"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            {/* Columna Arica */}
            <div className="mobiles-column">
              {["M01", "M05", "M13", "M15", "M17"].map((movil, index) =>
                movil.toLowerCase() === "admin" ? null : (
                  <div key={index} className="checkbox-item">
                    <input
                      type="checkbox"
                      id={`movil-${movil}`}
                      value={movil}
                      onChange={handleCheckboxChange}
                      checked={selectedMoviles.includes(movil)}
                    />
                    <label htmlFor={`movil-${movil}`}>{movil}</label>
                  </div>
                )
              )}
            </div>
            {/* Columna Antofagasta */}
            <div className="mobiles-column">
              {["M36", "M50", "M60", "M61", "M62"].map((movil, index) =>
                movil.toLowerCase() === "admin" ? null : (
                  <div key={index} className="checkbox-item">
                    <input
                      type="checkbox"
                      id={`movil-${movil}`}
                      value={movil}
                      onChange={handleCheckboxChange}
                      checked={selectedMoviles.includes(movil)}
                    />
                    <label htmlFor={`movil-${movil}`}>{movil}</label>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <button className="btn filter-btn" onClick={handleFilter}>
          Mostrar
        </button>

        {/* Botón para mostrar antenas */}
        <button
          className="btn filter-btn"
          style={{ marginTop: "10px" }}
          onClick={handleLoadAntennas}
        >
          Mostrar Antenas
        </button>

        {/* Filtro de antenas (checkboxes) */}
        {showAntennas && Object.keys(antennaFilters).length > 0 && (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <strong>Filtrar Antenas por Empresa:</strong>
            <div style={{ display: "flex", gap: "10px" }}>
              {Object.keys(antennaFilters).map((comp) => (
                <label key={comp} style={{ fontSize: "0.9rem" }}>
                  <input
                    type="checkbox"
                    name={comp}
                    checked={antennaFilters[comp]}
                    onChange={handleAntennaFilterChange}
                  />{" "}
                  {comp}
                </label>
              ))}
            </div>
          </div>
        )}

        {/* Caja de texto y botón para ingresar coordenadas personalizadas */}
        <div
          className="custom-marker-group"
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <input
            type="text"
            placeholder="Ej: -18.4666191,-70.2966116"
            value={customMarkerInput}
            onChange={(e) => setCustomMarkerInput(e.target.value)}
            className="input"
            style={{ width: "200px" }}
          />
          <button className="btn" onClick={handleLoadMarker}>
            Cargar
          </button>
        </div>

        {/* Lista de coordenadas cargadas */}
        {customMarkers.length > 0 && (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              alignItems: "center",
            }}
          >
            {customMarkers.map((coord, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "5px",
                  border: "1px solid #ccc",
                  padding: "2px 4px",
                  borderRadius: "3px",
                  width: "220px",
                  fontSize: "0.8rem",
                  lineHeight: "1.2",
                }}
              >
                <span>{coord.join(", ")}</span>
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "none",
                    borderRadius: "3px",
                    padding: "2px 6px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setCustomMarkers((prev) => prev.filter((_, i) => i !== index))
                  }
                >
                  X
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Barra de Progreso y Resumen */}
        <div className="progress-section">
          <div className="progress-bar" style={{ position: "relative" }}>
            <label>Progreso:</label>
            <input
              type="range"
              min="0"
              max="100"
              step="1"
              value={progress}
              onChange={(e) => setProgress(Number(e.target.value))}
              style={{ width: "200px", marginLeft: "10px" }}
            />
            <div
              className="progress-tooltip"
              style={{
                position: "absolute",
                top: "-30px",
                left: `${progress}%`,
                transform: "translateX(-50%)",
                background: "#fff",
                padding: "2px 5px",
                border: "1px solid #ccc",
                borderRadius: "3px",
                fontSize: "0.8rem",
              }}
            >
              {(() => {
                if (routes.length === 0) return "";
                const routeObj = routes[0];
                const partialCoords = getPartialCoords(routeObj.decodedCoords);
                if (partialCoords.length === 0) return "";
                const filteredData = filterBySchedule(routeObj.originalData);
                const [lat, lon] = partialCoords[partialCoords.length - 1];
                const nearestTime = findNearestTime(filteredData, lat, lon);
                if (!nearestTime) return "";
                const dateObj = parseLastReport(nearestTime);
                return dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
              })()}
            </div>
            <span style={{ marginLeft: "10px" }}>{progress}%</span>
          </div>

          <div className="mobile-details">
            <div className="mobile-card">
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Resumen General:
              </p>
              <p>
                Distancia Total: {totalDistance} km | Consumo Total: {totalFuel} litros
              </p>
            </div>
            {routes.map((r, index) => (
              <div className="mobile-card" key={index}>
                <p style={{ marginBottom: "5px" }}>
                  {r.mobile}
                  <span
                    style={{ cursor: "pointer", marginLeft: "10px", fontSize: "1.2rem" }}
                    onClick={() => toggleVisibility(r.mobile)}
                    title={r.visible ? "Ocultar ruta" : "Mostrar ruta"}
                  >
                    {r.visible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </p>
                <p>
                  Distancia: {r.distanceKm} km | Consumo: {(r.distanceKm / 7.4).toFixed(2)} L
                </p>
                <p>
                  <strong>Salida Local:</strong>{" "}
                  {r.exitTime ? r.exitTime.toLocaleTimeString() : "N/D"}
                </p>
                <p>
                  <strong>Entrada Local:</strong>{" "}
                  {r.entryTime ? r.entryTime.toLocaleTimeString() : "N/D"}
                </p>
                <p>
                  <strong>Tiempo activo:</strong> {r.activeTime} min
                </p>
                <p>
                  <strong>Detenido (General):</strong> {r.stopTime} min
                </p>
                <p>
                  <strong>Casa (Total):</strong> {r.casa} min
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mapa */}
      <div className="map-container">
        <MapContainer center={mapCenter} zoom={mapZoom} style={{ height: "100%", width: "100%" }}>
          <ChangeMapView center={mapCenter} zoom={mapZoom} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {routes
            .filter((route) => route.visible)
            .map((routeObj, idx) => {
              const partialCoords = getPartialCoords(routeObj.decodedCoords);
              return (
                <React.Fragment key={idx}>
                  <Polyline
                    positions={partialCoords}
                    pathOptions={{ color: routeObj.color, weight: 4, opacity: 0.9 }}
                  />
                  {/* Dibujar markers intermedios para todos los puntos */}
                  {partialCoords.map((coord, i) => (
                    <CircleMarker
                      key={`${routeObj.mobile}-point-${i}`}
                      center={coord}
                      radius={1}
                      pathOptions={{
                        color: routeObj.color,
                        fillColor: routeObj.color,
                        fillOpacity: 0.8,
                      }}
                    />
                  ))}
                  {/* Marker de inicio */}
                  {partialCoords.length > 0 && (
                    <Marker position={partialCoords[0]} icon={defaultIcon}>
                      <Tooltip permanent direction="top" offset={[0, -35]}>
                        <div>
                          <strong>{routeObj.mobile}</strong>
                          <br />
                          {routeObj.timestamps.length > 0
                            ? `Inicio: ${parseLastReport(routeObj.timestamps[0]).toLocaleTimeString()}`
                            : "Inicio: N/D"}
                        </div>
                      </Tooltip>
                    </Marker>
                  )}
                  {/* Marker de fin */}
                  {partialCoords.length > 1 && (
                    <Marker position={partialCoords[partialCoords.length - 1]} icon={defaultIcon}>
                      <Tooltip permanent direction="top" offset={[0, -35]}>
                        <div>
                          <strong>{routeObj.mobile}</strong>
                          <br />
                          {(() => {
                            const filteredData = filterBySchedule(routeObj.originalData);
                            const [lat, lon] = partialCoords[partialCoords.length - 1];
                            const nearestTime = findNearestTime(filteredData, lat, lon);
                            return nearestTime
                              ? `Fin: ${parseLastReport(nearestTime).toLocaleTimeString()}`
                              : "Fin: N/D";
                          })()}
                        </div>
                      </Tooltip>
                    </Marker>
                  )}
                  {/* Marker único para Casa */}
                  {routeObj.casa > 0 && routeObj.casaCoord && (
                    <Marker position={routeObj.casaCoord} icon={houseIcon}>
                      <Tooltip permanent direction="top" offset={[0, -35]}>
                        <div style={{ textAlign: "center" }}>
                          <strong>Casa: {routeObj.casa} min</strong>
                        </div>
                      </Tooltip>
                    </Marker>
                  )}
                  {/* Círculos para paradas: se dibuja un <Circle> de radio 50 metros en el centro de cada segmento de detención */}
                  {routeObj.stoppedSegments &&
                    routeObj.stoppedSegments.map((seg, i) => {
                      const center = getCenterOfCoords(seg.coords);
                      const durationMin = Math.floor(seg.stopDurationSec / 60);
                      const color = seg.stopDurationSec >= 600 ? "#000000" : "#ADD8E6"; // negro si >= 10 min, celeste claro si menor
                      return (
                        <Circle
                          key={`stop-${routeObj.mobile}-${i}`}
                          center={center}
                          radius={50} // en metros
                          pathOptions={{ color, fillColor: color, fillOpacity: 0.5 }}
                        >
                          <Tooltip direction="top">
                            <div style={{ textAlign: "center", fontSize: "0.8rem" }}>
                              <strong>{durationMin} min</strong>
                            </div>
                          </Tooltip>
                        </Circle>
                      );
                    })}
                </React.Fragment>
              );
            })}
          {/* Marker personalizado para cada coordenada cargada */}
          {customMarkers.map((coord, index) => (
            <Marker key={`custom-marker-${index}`} position={coord} icon={defaultIcon}>
              <Tooltip permanent direction="top" offset={[0, -35]}>
                <div>
                  <strong>Coordenada personalizada</strong>
                  <br />
                  {coord.join(", ")}
                </div>
              </Tooltip>
            </Marker>
          ))}
          {/* Antenas: se muestran si showAntennas es true y aplicando el filtro */}
          {showAntennas &&
            antennas
              .filter(
                (antena) =>
                  antennaFilters[getAntennaCompany(antena.Empresa)] &&
                  typeof antena.Latitud === "number" &&
                  typeof antena.Longitud === "number"
              )
              .map((antena, index) => {
                const comp = getAntennaCompany(antena.Empresa);
                return (
                  <CircleMarker
                    key={`antena-${index}`}
                    center={[antena.Latitud, antena.Longitud]}
                    radius={4}
                    pathOptions={{
                      color: antennaColors[comp],
                      fillColor: antennaColors[comp],
                      fillOpacity: 0.8,
                    }}
                  >
                    <Tooltip direction="top" offset={[0, -10]}>
                      <div style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        <strong>{antena.Empresa}</strong>
                        <br />
                        {antena.Dirección}
                      </div>
                    </Tooltip>
                  </CircleMarker>
                );
              })}
        </MapContainer>
      </div>

      {loading && <p style={{ textAlign: "center" }}>Cargando rutas...</p>}
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
    </div>
  );
};

export default RutaMoviles;