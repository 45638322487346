import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Switch from './Switch';

// Función para "trocear" un array en bloques de tamaño size (usada en desktop)
function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

// Estilos para desktop
const Container = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const CityWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  background: none;
  color: black;
  padding: 5px;
  text-align: center;

  body.dark-mode & {
    color: #e0e0e0;
  }
`;

const ColumnsWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MobileItem = styled.div`
  display: flex;
  align-items: center;
  background: none;
  color: black;
  padding: 5px;
  font-size: 0.85rem;

  body.dark-mode & {
    color: #e0e0e0;
  }
`;

// Estilos para mobile (menú y títulos de sección)
const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: black;
  padding: 10px;
  border-bottom: 1px solid #ccc;

  body.dark-mode & {
    color: #e0e0e0;
    border-color: #444;
  }
`;

const MobileSectionTitle = styled.h4`
  margin: 10px 0 5px;
  color: black;

  body.dark-mode & {
    color: #e0e0e0;
  }
`;

function MobileStatus() {
  const [mobiles, setMobiles] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  // Función para obtener las ubicaciones
  const fetchLocations = async () => {
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/ubicaciones.json');
      const data = await response.json();
      if (data.status === 'OK' && data.data) {
        setMobiles(data.data);
      }
    } catch (err) {
      console.error('Error al obtener ubicaciones:', err);
    }
  };

  useEffect(() => {
    fetchLocations();
    const intervalId = setInterval(fetchLocations, 30000); // Actualiza cada 30 segundos
    return () => clearInterval(intervalId);
  }, []);

  // Detectar si es dispositivo móvil (umbral: 768px)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Separar los móviles según ciudad
  const aricaUsernames = ['M01', 'M13', 'M05', 'M15', 'M17', 'admin'];
  const aricaMobiles = mobiles.filter((m) => aricaUsernames.includes(m.username));
  const antofagastaMobiles = mobiles.filter((m) => !aricaUsernames.includes(m.username));

  // Renderiza cada móvil (fila)
  const renderMobileItem = (mobile) => {
    const lastReportDate = dayjs.unix(mobile.lastReport);
    const formattedLastReport = lastReportDate.format('DD-MM-YY, HH:mm');
    const diffMinutes = dayjs().diff(lastReportDate, 'minute');
    const isOnline = diffMinutes < 15;

    return (
      <MobileItem key={mobile.username}>
        <strong style={{ marginRight: '5px' }}>{mobile.username}</strong>
        <Switch checked={isOnline} />
        <span style={{ marginLeft: '8px' }}>{formattedLastReport}</span>
      </MobileItem>
    );
  };

  // Renderizado para mobile: menú colapsable con título "GPS Moviles"
  if (isMobile) {
    return (
      <div>
        <MobileMenuHeader>
          <h3>GPS Móviles</h3>
          <button onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? 'Expandir' : 'Contraer'}
          </button>
        </MobileMenuHeader>
        {!collapsed && (
          <div style={{ padding: '10px' }}>
            <MobileSectionTitle>Arica</MobileSectionTitle>
            {aricaMobiles.map(renderMobileItem)}
            <MobileSectionTitle>Antofagasta</MobileSectionTitle>
            {antofagastaMobiles.map(renderMobileItem)}
          </div>
        )}
      </div>
    );
  }

  // Renderizado para desktop: layout original con columnas
  const chunkedArica = chunkArray(aricaMobiles, 2);
  const chunkedAntofagasta = chunkArray(antofagastaMobiles, 2);

  return (
    <Container>
      <CityWrapper>
        <Title>Arica</Title>
        <ColumnsWrapper>
          {chunkedArica.map((group, index) => (
            <Column key={`arica-col-${index}`}>
              {group.map(renderMobileItem)}
            </Column>
          ))}
        </ColumnsWrapper>
      </CityWrapper>
      <CityWrapper>
        <Title>Antofagasta</Title>
        <ColumnsWrapper>
          {chunkedAntofagasta.map((group, index) => (
            <Column key={`antofa-col-${index}`}>
              {group.map(renderMobileItem)}
            </Column>
          ))}
        </ColumnsWrapper>
      </CityWrapper>
    </Container>
  );
}

export default MobileStatus;
