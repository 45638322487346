import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, differenceInSeconds } from 'date-fns';
import es from 'date-fns/locale/es';
import '../styles/PedidosHistoricos.css';

import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import eliminarImg from '../images/eliminar.png';

function PedidosHistoricos() {
  const [userRole, setUserRole] = useState('');
  const [pedidos, setPedidos] = useState([]);
  const [filtros, setFiltros] = useState({
    fechaInicio: null,
    fechaFin: null,
    busqueda: ''
  });
  const [selectedFilters, setSelectedFilters] = useState({
    estado: [],
    movil: [],
    origen: [],
    ciudad: []
  });
  const [origenesUnicos, setOrigenesUnicos] = useState([]);
  const [estadosUnicos, setEstadosUnicos] = useState([]);
  const [movilesUnicos, setMovilesUnicos] = useState([]);
  const [ciudadesUnicas, setCiudadesUnicas] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [ultimaVersionJson, setUltimaVersionJson] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const headerRef = useRef(null);
  // Refs para tocar y distinguir tap de scroll
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);

  const normalizeDate = (date) => {
    if (!date) return null;
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  useEffect(() => {
    const storedRole = localStorage.getItem('role') || 'Supervisor';
    setUserRole(storedRole);
    const today = new Date();
    setFiltros((prev) => ({
      ...prev,
      fechaInicio: normalizeDate(today),
      fechaFin: normalizeDate(today)
    }));
  }, []);

  const cargarPedidosHistoricos = async () => {
    try {
      const response = await fetch(
        'https://pedidos.ayvgas.cl/pedidos/pedido_callcenter_historico.json',
        { cache: 'no-store' }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPedidos(data);

      const origenes = [
        ...new Set(data.filter((p) => p && p.campo_12).map((p) => p.campo_12))
      ].sort((a, b) => a.localeCompare(b));
      setOrigenesUnicos(origenes);

      const estados = [
        ...new Set(data.filter((p) => p && p.campo_6).map((p) => p.campo_6))
      ].sort((a, b) => a.localeCompare(b));
      setEstadosUnicos(estados);

      const moviles = [
        ...new Set(data.filter((p) => p && p.campo_11).map((p) => p.campo_11))
      ].sort((a, b) => a.localeCompare(b));
      setMovilesUnicos(moviles);

      const ciudades = [
        ...new Set(data.filter((p) => p && p.campo_yy).map((p) => p.campo_yy))
      ].sort((a, b) => a.localeCompare(b));
      setCiudadesUnicas(ciudades);
    } catch (error) {
      console.error('Error al cargar los pedidos históricos:', error);
    }
  };

  useEffect(() => {
    cargarPedidosHistoricos();
    const intervalId = setInterval(() => {
      cargarPedidosHistoricos();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [ultimaVersionJson]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFiltroChange = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  const handleCheckboxFilter = (columna, valor) => {
    const arr = selectedFilters[columna] || [];
    const newArr = arr.includes(valor)
      ? arr.filter((v) => v !== valor)
      : [...arr, valor];
    setSelectedFilters({ ...selectedFilters, [columna]: newArr });
  };

  const toggleFilterDropdown = (columna, e) => {
    e.stopPropagation();
    setActiveFilter(activeFilter === columna ? null : columna);
  };

  const parsearFecha = (fechaStr) => {
    if (!fechaStr || typeof fechaStr !== 'string') return null;
    const [fecha] = fechaStr.split(',');
    try {
      const [day, month, year] = fecha.trim().split('-');
      return normalizeDate(new Date(+year, +month - 1, +day));
    } catch (error) {
      console.error('Error al parsear la fecha:', error);
      return null;
    }
  };

  const ordenarPedidos = (lista) => {
    if (!sortConfig.key) return lista;
    return [...lista].sort((a, b) => {
      const valorA = a[sortConfig.key];
      const valorB = b[sortConfig.key];
      const numA = parseFloat(valorA);
      const numB = parseFloat(valorB);
      let comparacion = 0;
      if (!isNaN(numA) && !isNaN(numB)) {
        comparacion = numA - numB;
      } else {
        comparacion = String(valorA).localeCompare(String(valorB));
      }
      return sortConfig.direction === 'asc' ? comparacion : -comparacion;
    });
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const filtrarPedidos = (pedido) => {
    if (!pedido) return false;
    const { fechaInicio, fechaFin, busqueda } = filtros;
    if (busqueda) {
      const norm = busqueda.toLowerCase();
      const concat = Object.values(pedido).join(' ').toLowerCase();
      if (!concat.includes(norm)) return false;
    }
    const fechaPedido = parsearFecha(pedido.campo_5);
    if (!fechaPedido) return false;
    const ini = normalizeDate(fechaInicio);
    const fin = normalizeDate(fechaFin);
    if (ini && fechaPedido < ini) return false;
    if (fin && fechaPedido > fin) return false;
    if (selectedFilters.estado.length > 0 && !selectedFilters.estado.includes(pedido.campo_6)) return false;
    if (selectedFilters.movil.length > 0 && !selectedFilters.movil.includes(pedido.campo_11)) return false;
    if (selectedFilters.origen.length > 0 && !selectedFilters.origen.includes(pedido.campo_12)) return false;
    if (selectedFilters.ciudad.length > 0 && !selectedFilters.ciudad.includes(pedido.campo_yy)) return false;
    return true;
  };

  const obtenerSemaforoImagen = (ciudad, tiempo) => {
    if (!tiempo) return img5;
    const tiempoEnSegundos = differenceInSeconds(
      parse(tiempo, 'HH:mm:ss', new Date()),
      parse('00:00:00', 'HH:mm:ss', new Date())
    );
    if (ciudad === 'Arica') {
      if (tiempoEnSegundos < 450) return img1;
      if (tiempoEnSegundos < 900) return img2;
      if (tiempoEnSegundos < 1350) return img3;
      if (tiempoEnSegundos < 1800) return img4;
      return img5;
    }
    if (ciudad === 'Antofagasta') {
      if (tiempoEnSegundos < 600) return img1;
      if (tiempoEnSegundos < 1200) return img2;
      if (tiempoEnSegundos < 1800) return img3;
      if (tiempoEnSegundos < 2400) return img4;
      return img5;
    }
    return img5;
  };

  const eliminarDuplicados = async () => {
    const confirmacion = window.confirm('¿Estás seguro de que deseas eliminar los duplicados?');
    if (!confirmacion) return;
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/limpiaduplicados.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      });
      if (response.ok) {
        await response.json();
        alert('Duplicados eliminados exitosamente.');
        cargarPedidosHistoricos();
      } else {
        throw new Error(`Error al eliminar duplicados: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error al eliminar duplicados:', error);
      alert('Ocurrió un error al eliminar los duplicados.');
    }
  };

  const eliminarPedido = async (campo2, index) => {
    const confirmacion = window.confirm('¿Estás seguro de que deseas eliminar este pedido?');
    if (!confirmacion) return;
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/eliminar_pedido.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ campo_2: campo2 })
      });
      if (response.ok) {
        alert('Pedido eliminado exitosamente.');
        cargarPedidosHistoricos();
      } else {
        throw new Error(`Error al eliminar pedido: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error al eliminar pedido:', error);
      alert('Ocurrió un error al eliminar el pedido.');
    }
  };

  const pedidosFiltrados = ordenarPedidos(pedidos.filter(filtrarPedidos));

  return (
    <div className="pedidos-historicos-container" ref={headerRef}>
      <h1>Pedidos Históricos</h1>
      {/* Filtros generales */}
      <div className="filtros-container">
        <input
          type="text"
          name="busqueda"
          value={filtros.busqueda}
          placeholder="Buscar en todas las columnas..."
          onChange={handleFiltroChange}
        />
        <DatePicker
          selected={filtros.fechaInicio}
          onChange={(date) =>
            setFiltros({ ...filtros, fechaInicio: normalizeDate(date) })
          }
          placeholderText="Desde fecha"
          dateFormat="dd-MM-yyyy"
          locale={es}
          isClearable
        />
        <DatePicker
          selected={filtros.fechaFin}
          onChange={(date) =>
            setFiltros({ ...filtros, fechaFin: normalizeDate(date) })
          }
          placeholderText="Hasta fecha"
          dateFormat="dd-MM-yyyy"
          locale={es}
          isClearable
        />
        <button className="eliminar-duplicados-btn" onClick={eliminarDuplicados}>
          Eliminar Duplicados
        </button>
      </div>
      {/* Contenedor con scroll vertical y horizontal */}
      <div className="table-container">
        <table className="pedidos-historicos-tabla">
          <thead>
            <tr>
              <th onClick={() => requestSort('campo_1')}>#</th>
              <th onClick={() => requestSort('campo_2')}>N° del Pedido</th>
              <th>Semáforo</th>
              <th onClick={() => requestSort('campo_4')}>Teléfono</th>
              <th onClick={() => requestSort('campo_5')}>Fecha</th>
              <th onClick={() => requestSort('campo_6')} style={{ position: 'relative' }}>
                Estado
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={(e) => toggleFilterDropdown('estado', e)}
                >
                  &#x1F50D;
                </span>
                {activeFilter === 'estado' && (
                  <div
                    className="filter-dropdown"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '150px',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      borderRadius: '4px',
                      zIndex: 999,
                      padding: '5px'
                    }}
                  >
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                      {estadosUnicos.sort((a, b) => a.localeCompare(b)).map((estado) => (
                        <li key={estado} style={{ padding: '3px 0' }}>
                          <label style={{ cursor: 'pointer', fontSize: '0.9em' }}>
                            <input
                              type="checkbox"
                              checked={selectedFilters.estado.includes(estado)}
                              onChange={() => handleCheckboxFilter('estado', estado)}
                              style={{ marginRight: '5px' }}
                            />
                            {estado}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </th>
              <th onClick={() => requestSort('campo_7')}>Dirección</th>
              <th onClick={() => requestSort('campo_8')}>Producto1</th>
              <th onClick={() => requestSort('campo_9')}>Producto2</th>
              <th onClick={() => requestSort('campo_13')}>Producto3</th>
              <th onClick={() => requestSort('campo_14')}>Producto4</th>
              <th onClick={() => requestSort('campo_11')} style={{ position: 'relative' }}>
                Móvil
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={(e) => toggleFilterDropdown('movil', e)}
                >
                  &#x1F50D;
                </span>
                {activeFilter === 'movil' && (
                  <div
                    className="filter-dropdown"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '150px',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      borderRadius: '4px',
                      zIndex: 999,
                      padding: '5px'
                    }}
                  >
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                      {movilesUnicos.sort((a, b) => a.localeCompare(b)).map((movil) => (
                        <li key={movil} style={{ padding: '3px 0' }}>
                          <label style={{ cursor: 'pointer', fontSize: '0.9em' }}>
                            <input
                              type="checkbox"
                              checked={selectedFilters.movil.includes(movil)}
                              onChange={() => handleCheckboxFilter('movil', movil)}
                              style={{ marginRight: '5px' }}
                            />
                            {movil}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </th>
              <th onClick={() => requestSort('campo_12')} style={{ position: 'relative' }}>
                Origen
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={(e) => toggleFilterDropdown('origen', e)}
                >
                  &#x1F50D;
                </span>
                {activeFilter === 'origen' && (
                  <div
                    className="filter-dropdown"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '150px',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      borderRadius: '4px',
                      zIndex: 999,
                      padding: '5px'
                    }}
                  >
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                      {origenesUnicos.sort((a, b) => a.localeCompare(b)).map((origen) => (
                        <li key={origen} style={{ padding: '3px 0' }}>
                          <label style={{ cursor: 'pointer', fontSize: '0.9em' }}>
                            <input
                              type="checkbox"
                              checked={selectedFilters.origen.includes(origen)}
                              onChange={() => handleCheckboxFilter('origen', origen)}
                              style={{ marginRight: '5px' }}
                            />
                            {origen}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </th>
              <th onClick={() => requestSort('campo_yy')} style={{ position: 'relative' }}>
                Ciudad
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={(e) => toggleFilterDropdown('ciudad', e)}
                >
                  &#x1F50D;
                </span>
                {activeFilter === 'ciudad' && (
                  <div
                    className="filter-dropdown"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '150px',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      border: '1px solid #ccc',
                      background: '#fff',
                      borderRadius: '4px',
                      zIndex: 999,
                      padding: '5px'
                    }}
                  >
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                      {ciudadesUnicas.sort((a, b) => a.localeCompare(b)).map((ciudad) => (
                        <li key={ciudad} style={{ padding: '3px 0' }}>
                          <label style={{ cursor: 'pointer', fontSize: '0.9em' }}>
                            <input
                              type="checkbox"
                              checked={selectedFilters.ciudad.includes(ciudad)}
                              onChange={() => handleCheckboxFilter('ciudad', ciudad)}
                              style={{ marginRight: '5px' }}
                            />
                            {ciudad}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </th>
              <th onClick={() => requestSort('campo_xx')}>Tiempo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pedidosFiltrados.map((pedido, index) => {
              const semaforoImg = obtenerSemaforoImagen(pedido.campo_yy, pedido.campo_xx);
              return (
                <tr key={index} className={selectedRow === index ? 'selected' : ''}>
                  {/* La primera columna es clickeable para seleccionar la fila */}
                  <td
                    onClick={() => setSelectedRow(index)}
                    onTouchStart={(e) => {
                      touchStartX.current = e.touches[0].clientX;
                      touchStartY.current = e.touches[0].clientY;
                    }}
                    onTouchMove={(e) => {
                      const deltaX = e.touches[0].clientX - touchStartX.current;
                      const deltaY = e.touches[0].clientY - touchStartY.current;
                      if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                        // Se está haciendo scroll; no seleccionamos
                        setSelectedRow(null);
                      }
                    }}
                    onTouchEnd={(e) => {
                      const deltaX = e.changedTouches[0].clientX - touchStartX.current;
                      const deltaY = e.changedTouches[0].clientY - touchStartY.current;
                      if (Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
                        setSelectedRow(index);
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {pedido.campo_1 || index + 1}
                  </td>
                  <td>{pedido.campo_2}</td>
                  <td>
                    <img src={semaforoImg} alt="Semáforo" width="40" />
                  </td>
                  <td>{pedido.campo_4}</td>
                  <td>{pedido.campo_5}</td>
                  <td>{pedido.campo_6}</td>
                  <td>{pedido.campo_7}</td>
                  <td>{pedido.campo_8}</td>
                  <td>{pedido.campo_9}</td>
                  <td>{pedido.campo_13}</td>
                  <td>{pedido.campo_14}</td>
                  <td>{pedido.campo_11}</td>
                  <td>{pedido.campo_12}</td>
                  <td>{pedido.campo_yy}</td>
                  <td>{pedido.campo_xx}</td>
                  <td>
                    {(userRole === 'Administrador' || userRole === 'Supervisor') && (
                      <img
                        src={eliminarImg}
                        alt="Eliminar Pedido"
                        width="30"
                        style={{ cursor: 'pointer' }}
                        onClick={() => eliminarPedido(pedido.campo_2, index)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PedidosHistoricos;
