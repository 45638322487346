// src/components/Switch.jsx
import React from 'react';
import styled from 'styled-components';

const Switch = ({ checked }) => {
  return (
    <StyledWrapper>
      <div className="checkbox-con">
        <input id="checkbox" type="checkbox" checked={checked} readOnly />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .checkbox-con {
    margin: 5px; /* Reducido el margen */
    display: flex;
    align-items: center;
    color: white;
  }

  .checkbox-con input[type="checkbox"] {
    appearance: none;
    width: 30px;  /* Achicado */
    height: 20px; /* Achicado */
    border: 2px solid #ff0000;
    border-radius: 15px;
    background: #f1e1e1;
    position: relative;
    box-sizing: border-box;
  }

  .checkbox-con input[type="checkbox"]::before {
    content: "";
    width: 10px; /* Achicado */
    height: 10px; /* Achicado */
    background: rgba(234, 7, 7, 0.5);
    border: 2px solid #ea0707;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 20%);
    transition: all 0.3s ease-in-out;
  }

  .checkbox-con input[type="checkbox"]::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 23 23' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55021 5.84315L17.1568 16.4498L16.4497 17.1569L5.84311 6.55026L6.55021 5.84315Z' fill='%23EA0707' fill-opacity='0.89'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.1567 6.55021L6.55012 17.1568L5.84302 16.4497L16.4496 5.84311L17.1567 6.55021Z' fill='%23EA0707' fill-opacity='0.89'/%3E%3C/svg%3E");
    position: absolute;
    top: 0;
    left: 15px;
  }

  .checkbox-con input[type="checkbox"]:checked {
    border: 2px solid #02c202;
    background: #e2f1e1;
  }

  .checkbox-con input[type="checkbox"]:checked::before {
    background: rgba(2, 194, 2, 0.5);
    border: 2px solid #02c202;
    transform: translate(110%, 20%);
    transition: all 0.3s ease-in-out;
  }

  .checkbox-con input[type="checkbox"]:checked::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 15 13' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8185 0.114533C15.0314 0.290403 15.0614 0.605559 14.8855 0.818454L5.00187 12.5L0.113036 6.81663C-0.0618274 6.60291 -0.0303263 6.2879 0.183396 6.11304C0.397119 5.93817 0.71213 5.96967 0.886994 6.18339L5.00187 11L14.1145 0.181573C14.2904 -0.0313222 14.6056 -0.0613371 14.8185 0.114533Z' fill='%2302C202' fill-opacity='0.9'/%3E%3C/svg%3E");
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;

export default Switch;
