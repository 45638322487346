import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, roles }) {
  const token = localStorage.getItem('token');
  // Cambiamos a "role"
  const userRole = localStorage.getItem('role');

  // Primero verificar si hay token (usuario logueado)
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Luego verificar que el rol del usuario esté en la lista de roles permitidos
  if (roles && !roles.includes(userRole)) {
    alert('No tienes permisos para acceder a esta página.');
    return <Navigate to="/welcome/dashboard" />;
  }

  return children;
}

export default ProtectedRoute;
