import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, Circle, OverlayView } from '@react-google-maps/api';

// Importación de imágenes para los íconos
import amarillo from '../images/map marker/amarillo.png';
import azul from '../images/map marker/azul.png';
import celeste from '../images/map marker/celeste.png';
import morado from '../images/map marker/morado.png';
import naranjaFuerte from '../images/map marker/naranja fuerte.png';
import naranja from '../images/map marker/naranja.png';
import rojoItaliano from '../images/map marker/rojo italiano.png';
import rojo from '../images/map marker/rojo.png';
import verdeOscuro from '../images/map marker/verde oscuro.png';
import verde from '../images/map marker/verde.png';
import violeta from '../images/map marker/violeta.png';

// Mapeo de íconos para los markers
const iconMapping = {
  M01: amarillo,
  M05: azul,
  M13: celeste,
  M15: morado,
  M36: naranjaFuerte,
  M50: violeta,
  M60: rojoItaliano,
  M61: verde,
  M62: verdeOscuro,
};

// Mapeo de colores para los círculos
const colorMapping = {
  M01: '#FFD700',    // amarillo
  M05: '#0000FF',    // azul
  M13: '#87CEEB',    // celeste
  M15: '#800080',    // morado
  M36: '#FFA500',    // naranja fuerte
  M50: '#EE82EE',    // violeta
  M60: '#FF0000',    // rojo italiano
  M61: '#008000',    // verde
  M62: '#006400',    // verde oscuro
};

// Opciones para ocultar nombres de negocios locales (poi.business)
const mapOptions = {
  styles: [
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

function GoogleCallCenterMap() {
  const [locations, setLocations] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [error, setError] = useState(null);
  
  // selectedMarker: almacena la ubicación del marker clicado (para tooltip detallado)
  const [selectedMarker, setSelectedMarker] = useState(null);

  // --- 1. FETCH DE UBICACIONES ---
  const fetchLocations = async () => {
    try {
      const response = await fetch(`https://pedidos.ayvgas.cl/ubicaciones.json?t=${Date.now()}`);
      if (!response.ok) {
        throw new Error(`HTTP error al leer ubicaciones.json: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === 'OK' && data.data) {
        setLocations(data.data);
      } else {
        throw new Error('El JSON de ubicaciones no contiene datos válidos.');
      }
    } catch (err) {
      console.error('Error al leer ubicaciones.json:', err);
      setError(err.message);
    }
  };

  // --- 2. FETCH DE PEDIDOS ---
  const fetchPedidos = async () => {
    try {
      const response = await fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json');
      if (!response.ok) {
        throw new Error(`HTTP error al leer pedido_callcenter.json: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setPedidos(data);
      } else if (data && typeof data === 'object') {
        setPedidos(Object.values(data));
      } else {
        setPedidos([]);
      }
    } catch (err) {
      console.error('Error al leer pedido_callcenter.json:', err);
      setError(err.message);
    }
  };

  // --- 3. ACTUALIZACIÓN PERIÓDICA ---
  useEffect(() => {
    const updateData = async () => {
      await fetchLocations();
      await fetchPedidos();
    };
    updateData();
    const interval = setInterval(updateData, 10000);
    return () => clearInterval(interval);
  }, []);

  // --- 4. ESTILOS Y CENTROS DE MAPAS ---
  const mapContainerStyle = { height: '100%', width: '100%' };
  const aricaCenter = { lat: -18.4583, lng: -70.3126 };
  const antofagastaCenter = { lat: -23.61, lng: -70.389 };

  // --- 5. FILTRO DE UBICACIONES ---
  const aricaLocations = locations.filter(loc => loc.latitude > -19 && loc.latitude < -17);
  const antofagastaLocations = locations.filter(loc => loc.latitude < -22 && loc.latitude > -24);

  // --- 6. FILTRO DE PEDIDOS POR CIUDAD ---
  const aricaPedidos = pedidos.filter(p => p.campo_yy && p.campo_yy.toLowerCase() === 'arica');
  const antofagastaPedidos = pedidos.filter(p => p.campo_yy && p.campo_yy.toLowerCase() === 'antofagasta');

  // --- 7. OBTENER POSICIÓN DE PEDIDO DESDE campo_geocode ---
  const getGeocodePosition = (pedido) => {
    if (!pedido.campo_geocode) return null;
    const parts = pedido.campo_geocode.split(',');
    if (parts.length === 2) {
      const lat = parseFloat(parts[0]);
      const lng = parseFloat(parts[1]);
      if (!isNaN(lat) && !isNaN(lng)) {
        return { lat, lng };
      }
    }
    return null;
  };

  // --- 8. HANDLERS DE CLICK ---
  const handleMarkerClick = (loc) => {
    setSelectedMarker(loc);
  };

  const handleCloseOverlay = () => {
    setSelectedMarker(null);
  };

  // --- 9. FORMATEAR TIEMPO DE REPORTE ---
  const formatLastReport = (unixTime) => {
    if (!unixTime) return 'N/A';
    return new Date(unixTime * 1000).toLocaleString();
  };

  // Buscamos en la lista actualizada de ubicaciones la que coincide con el marker seleccionado
  const currentSelectedArica = selectedMarker && aricaLocations.find(loc => loc.username === selectedMarker.username);
  const currentSelectedAntofagasta = selectedMarker && antofagastaLocations.find(loc => loc.username === selectedMarker.username);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw', position: 'relative' }}>
      {error && (
        <div style={{
          position: 'absolute',
          top: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'red',
          color: 'white',
          padding: '10px',
          zIndex: 1000,
          borderRadius: '5px'
        }}>
          Error: {error}
        </div>
      )}

      {/* --- MAPA DE ARICA --- */}
      <div style={{ flex: 1, position: 'relative' }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          defaultCenter={aricaCenter}
          zoom={13}
          options={mapOptions}
        >
          {/* Marcadores con tooltip permanente */}
          {aricaLocations.map((loc, index) => (
            <React.Fragment key={`arica-${index}`}>
              <Marker
                position={{ lat: loc.latitude, lng: loc.longitude }}
                icon={{
                  url: iconMapping[loc.username] || violeta,
                  scaledSize: new window.google.maps.Size(45, 45),
                  anchor: new window.google.maps.Point(15, 30),
                }}
                onClick={() => handleMarkerClick(loc)}
                
              />
              <OverlayView
                position={{ lat: loc.latitude, lng: loc.longitude }}
                mapPaneName={OverlayView.OVERLAY_LAYER}
              >
                <div className="my-marker-tooltip">{loc.username}</div>
              </OverlayView>
            </React.Fragment>
          ))}

          {/* Círculos para pedidos */}
          {aricaPedidos.map((pedido, index) => {
            const pos = getGeocodePosition(pedido);
            if (!pos) return null;
            return (
              <Circle
                key={`arica-pedido-${index}`}
                center={pos}
                radius={50} // 50 metros
                options={{
                  strokeColor:  '#000000',
                  fillColor: colorMapping[pedido.campo_11] || '#000000',
                  fillOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            );
          })}

          {/* Tooltip detallado al hacer clic */}
          {currentSelectedArica && (
            <OverlayView
              position={{ lat: currentSelectedArica.latitude, lng: currentSelectedArica.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="custom-info-window">
                <div className="close-button" onClick={handleCloseOverlay}>X</div>
                <div>
                  <span>Usuario: {selectedMarker.username}</span>
                  <span>Estado: {selectedMarker.status || 'N/A'}</span>
                  <span>Último Reporte: {formatLastReport(selectedMarker.lastReport)}</span>
                </div>
              </div>
            </OverlayView>
          )}
        </GoogleMap>
      </div>

      {/* --- MAPA DE ANTOFAGASTA --- */}
      <div style={{ flex: 1, position: 'relative' }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          defaultCenter={antofagastaCenter}
          zoom={12}
          options={mapOptions}
        >
          {antofagastaLocations.map((loc, index) => (
            <React.Fragment key={`antofagasta-${index}`}>
              <Marker
                position={{ lat: loc.latitude, lng: loc.longitude }}
                icon={{
                  url: iconMapping[loc.username] || violeta,
                  scaledSize: new window.google.maps.Size(45, 45),
                  anchor: new window.google.maps.Point(15, 30),
                }}
                onClick={() => handleMarkerClick(loc)}
                
              />
              <OverlayView
                position={{ lat: loc.latitude, lng: loc.longitude }}
                mapPaneName={OverlayView.OVERLAY_LAYER}
              >
                <div className="my-marker-tooltip">{loc.username}</div>
              </OverlayView>
            </React.Fragment>
          ))}

          {antofagastaPedidos.map((pedido, index) => {
            const pos = getGeocodePosition(pedido);
            if (!pos) return null;
            return (
              <Circle
                key={`antofagasta-pedido-${index}`}
                center={pos}
                radius={70}
                options={{
                  strokeColor: '#000000',
                  fillColor: colorMapping[pedido.campo_11] || '#000000',
                  fillOpacity: 0.35,
                  strokeWeight: 2,
                }}
              />
            );
          })}

          {currentSelectedAntofagasta && (
            <OverlayView
              position={{ lat: currentSelectedAntofagasta.latitude, lng: currentSelectedAntofagasta.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="custom-info-window">
                <div className="close-button" onClick={handleCloseOverlay}>X</div>
                <div>
                  <span>Usuario: {selectedMarker.username}</span>
                  <span>Estado: {selectedMarker.status || 'N/A'}</span>
                  <span>Último Reporte: {formatLastReport(selectedMarker.lastReport)}</span>
                </div>
              </div>
            </OverlayView>
          )}
        </GoogleMap>
      </div>
    </div>
  );
}

export default GoogleCallCenterMap;
