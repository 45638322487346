// src/components/Layout.jsx
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../styles/Layout.css';

function Layout() {
  const location = useLocation();

  // Detectar si estamos en una ruta de mapas
  const isMapView = location.pathname.includes('/welcome/mapa');

  return (
    <div className="layout collapsed">
      <Sidebar />
      <div className={`main-content ${isMapView ? 'no-padding' : ''}`}>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
